import { RouteConfig } from 'vue-router'

const UsersRoutes: Array<RouteConfig> = [
  {
    path: '/usersList',
    name: 'usersList',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Users/UsersList/index.vue'),
  },
]
export default UsersRoutes
