import axios, { AxiosRequestConfig } from 'axios'
import authModule from '@/store/auth'

const BASE_URL = process.env.VUE_APP_API_URL

const timeout = 50000

const http = axios.create({
  baseURL: BASE_URL,
  timeout: timeout,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
} as AxiosRequestConfig)

const httpMulti = axios.create({
  baseURL: BASE_URL,
  timeout: timeout,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data',
  },
})

http.interceptors.request.use(
  (config) => {
    tokenInterceptor(config)
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

http.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    return errorInterceptor(error) // handle the request && error.message === 'Token has expired' && getToken()
  },
)
httpMulti.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    return errorInterceptor(error) // handle the request && error.message === 'Token has expired' && getToken()
  },
)

const isCorrectRefreshError = (error: { status: number; message: string }) =>
  error.status === 401

const getStatus = (error: any): { status: number; message: string } => {
  let status,
    message = null
  try {
    status = error.response.status
    message = error.response.data.message
  } catch (error) {
    status = 500
    message = ''
  }
  return { status, message }
}

const errorInterceptor = async (error: any) => {
  const errorStatus = getStatus(error)
  if (isCorrectRefreshError(errorStatus)) await authModule.logout()
  return Promise.reject(error)
}

const tokenInterceptor = async (config: AxiosRequestConfig) => {
  const token = authModule.AccessToken
  config.headers.Authorization = `Bearer ${token}`
  if (token !== null && config.url !== 'api/account/token/refresh') {
    if (!authModule.isValidToken) {
      await authModule.refresh()
      config.headers.Authorization = `Bearer ${authModule.AccessToken}`
    }
  }
}

export default http
