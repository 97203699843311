import { RouteConfig } from 'vue-router'

const InsuranceRoutes: Array<RouteConfig> = [
  {
    path: '/insurance',
    name: 'Insurance',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Insurance/Insurance/Insurance.vue'),
  },
  {
    path: '/insurance/typePolicyInsurance',
    name: 'TypePolicyInsurance',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        '@/views/Insurance/Settings/TypePolicyInsurance/TypePolicyInsurance.vue'
      ),
  },
  {
    path: '/insurance/typePeriodicityInsurance',
    name: 'TypePeriodicityInsurance',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        '@/views/Insurance/Settings/TypePeriodicitycInsurance/TypePeriodicityInsurance.vue'
      ),
  },
  {
    path: '/insurance/typePaymentInsurance',
    name: 'TypePaymentInsurance',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        '@/views/Insurance/Settings/TypePaymentInsurance/TypePaymentInsurance.vue'
      ),
  },
  {
    path: '/insurance/typeStateInsurance',
    name: 'TypeStateInsurance',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        '@/views/Insurance/Settings/TypeStateInsurance/TypeStateInsurance.vue'
      ),
  },
  {
    path: '/insurance/insurancePayment',
    name: 'InsurancePayment',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import('@/views/Insurance/InsurancePayment/InsurancePayment.vue'),
  },
  {
    path: '/insurance/ExpiredPolicies',
    name: 'expire_polices',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import('@/views/Insurance/ExpiredPolicies/ExpiredPolicies.vue'),
  },
]
export default InsuranceRoutes
