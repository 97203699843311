import { RouteConfig } from 'vue-router'

const emailRoutes: Array<RouteConfig> = [
  {
    path: '/e-mail',
    name: 'Email',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/E-mail/Email.vue'),
  },
]
export default emailRoutes
