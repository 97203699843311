import { RouteConfig } from 'vue-router'

const AccountsRoutes: Array<RouteConfig> = [
  {
    path: '/accounts',
    name: 'accounts',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Account/index.vue'),
    children: [
      {
        path: '/accounts/user',
        name: 'user',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/Account/User.vue'),
      },
    ],
  },
]

export default AccountsRoutes
