import { RouteConfig } from 'vue-router'

const CCSSRoutes: Array<RouteConfig> = [
  {
    path: '/legal',
    name: 'Legal',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Legal/Legal/Legal.vue'),
  },
  {
    path: '/legal/legalDocument',
    name: 'LegalDocument',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Legal/Document/Document.vue'),
  },
  {
    path: '/legal/legalObservation',
    name: 'LegalObservation',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Legal/Observation/Observation.vue'),
  },
]
export default CCSSRoutes
