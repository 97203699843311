import { RouteConfig } from 'vue-router'

const DashboardRoutes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      requiresAuth: true,
      //allowedRoles: ['SYS', 'ADMIN', 'NON'],
    },
    component: () => import('@/views/Dashboard/Dashboard.vue'),
  },
  {
    path: '/firstLogin',
    name: 'firstLogin',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/firtsLogin/index.vue'),
  },
]

export default DashboardRoutes
