import { RouteConfig } from 'vue-router'

const crmRoutes: Array<RouteConfig> = [
  {
    path: '/crm',
    name: 'Crm',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Crm/Crm/Crm.vue'),
  },
  {
    path: '/crm/dependentCrm',
    name: 'DependentCrm',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Crm/Dependent/Dependent.vue'),
  },
  {
    path: '/crm/observationCrm',
    name: 'ObservationCrm',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Crm/Observation/Observation.vue'),
  },
  {
    path: '/crm/personalDetail',
    name: 'PersonalDetail',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Crm/PersonalDetail/PersonalDetail.vue'),
  },
  {
    path: '/crm/reportIdentification',
    name: 'ReportIdentification',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import('@/views/Crm/ReportIdentification/ReportIdentification.vue'),
  },
  {
    path: '/crm/reportMemberShip',
    name: 'ReportMemberShip',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import('@/views/Crm/ReportMemberShip/ReportMemberShip.vue'),
  },
  {
    path: '/crm/activeShip',
    name: 'active_memberships',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import('@/views/Crm/ActivememberShip/ActiveMemberShip.vue'),
  },
  {
    path: '/crm/inactiveShip',
    name: 'inactive_memberships',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import('@/views/Crm/InactiveMemberShip/InactiveMemberShip.vue'),
  },
]
export default crmRoutes
