import store from '.'
import { Module, Mutation, Action, VuexModule } from 'vuex-class-modules'
import http from '@/services'
import { StoreNames } from '@/config'
import { Login, Token } from '@/Models'
import menuModule from './menu'
@Module({ generateMutationSetters: true })
class Auth extends VuexModule {
  private userData: any = JSON.parse(localStorage.getItem('userData') ?? '{}')
  private _token: string | null = localStorage.getItem(StoreNames.AUTH_TOKEN)

  get user() {
    return this.userData
  }

  private _access_token: string | null = localStorage.getItem(
    StoreNames.ACCESS_TOKEN,
  )
  private _refresh_token: string | null = localStorage.getItem(
    StoreNames.REFRESH_TOKEN,
  )

  get Roles(): { name: string }[] {
    if (!this._access_token) return []
    const tokenJSON = JSON.parse(atob(this._access_token.split('.')[1]))
    return tokenJSON.groups
  }

  get Name(): string {
    if (!this._access_token) return 'Anonymous'
    const tokenJSON = JSON.parse(atob(this._access_token.split('.')[1]))
    return tokenJSON.user_name
  }

  get ID(): string | null {
    if (!this._access_token) return null
    const tokenJSON = JSON.parse(atob(this._access_token.split('.')[1]))
    return tokenJSON.user_id
  }

  get IsTemp(): string | false {
    if (!this._access_token) return false
    const tokenJSON = JSON.parse(atob(this._access_token.split('.')[1]))
    return tokenJSON.is_temp_login
  }

  get isValidToken(): boolean {
    if (!this._access_token) return false
    const tokenJSON = JSON.parse(atob(this._access_token.split('.')[1]))
    const exp = Number(tokenJSON.exp) * 1000
    const time = new Date().getTime()
    return exp > time
  }

  get Token(): string | null {
    return this._token
  }

  get AccessToken(): string | null {
    return this._access_token
  }

  get RefreshToken(): string | null {
    return this._refresh_token
  }

  get isAuthenticated(): boolean {
    return this._token !== null
  }
  @Mutation
  SET_USERDATA(userData: any) {
    this.userData = userData
  }

  @Mutation
  setToken(payload: Token) {
    this._token = JSON.stringify(payload)
    this._access_token = payload.access
    this._refresh_token = payload.refresh
    localStorage.setItem(StoreNames.AUTH_TOKEN, this._token)
    localStorage.setItem(StoreNames.ACCESS_TOKEN, this._access_token)
    localStorage.setItem(StoreNames.REFRESH_TOKEN, this._refresh_token)
  }

  @Mutation
  clearToken() {
    this._token = null
    this._access_token = null
    this._refresh_token = null
    localStorage.removeItem(StoreNames.AUTH_TOKEN)
    localStorage.removeItem(StoreNames.REFRESH_TOKEN)
    localStorage.removeItem(StoreNames.ACCESS_TOKEN)
    menuModule.clearMenu()
  }
  @Mutation
  extractTokenData(jwt: string) {
    try {
      if (jwt) {
        const jwtData = atob(jwt.split('.')[1])
        this.userData = JSON.parse(jwtData)
        localStorage.setItem('userData', JSON.stringify(this.userData))
      } else {
        this.userData = {}
      }
    } catch {
      this.userData = {}
    }
  }

  @Action
  async login(payload: Login) {
    const response = await http.post('api/account/token/', payload)

    this.setToken(response.data)
    if (this.Roles.length == 1) await menuModule.initMenu(this.Roles[0].name)
    return Promise.resolve(response.data)
  }

  @Action
  async logout() {
    if (this._refresh_token)
      await http.post('api/account/token_logout/', {
        refresh_token: this._refresh_token,
      })
    this.clearToken()
    localStorage.clear()
  }

  @Action
  async refresh() {
    const response = await http.post('api/account/token/refresh/', {
      refresh: this._refresh_token,
    })
    this.setToken(response.data)
  }
}

const authModule = new Auth({ store, name: 'auth' })
export default authModule
