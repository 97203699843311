import store from '.'
import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import router from '@/router'
import http, { breadcrumb } from '@/services'
import { Breadcrumb, Menu, Module as ModuleModel } from '@/Models'
import { StoreNames } from '@/config'

@Module({ generateMutationSetters: true })
class MenuStore extends VuexModule {
  private _routes_empy_layout = ['/']
  private _routes_single_layout = ['']
  private _menu: string | null = localStorage.getItem(StoreNames.USER_MENU)
  private _id_param: string | null = localStorage.getItem(StoreNames.ID_PARAM)
  private _perPage_param: string | null =
    localStorage.getItem(StoreNames.PERPAGE_PARAM) ?? String(5)

  get id_param(): string | null {
    return this._id_param
  }
  get perPage_param(): string | null {
    return this._perPage_param
  }

  get currentTabComponent(): string {
    if (
      this._routes_empy_layout.includes(router.app.$route.path) ||
      router.app.$route.name == '404'
    )
      return 'EmpyLayout'
    if (this._routes_single_layout.includes(router.app.$route.path))
      return 'SingleLayout'
    return 'BoxLayout'
  }

  get user_modules(): ModuleModel[] {
    if (!this._menu) return []
    const menu = JSON.parse(this._menu) as Menu
    return menu.modules.filter((m) => m.visible_in_menu && m.pages_exist)
  }

  get user_permissions(): string[] {
    if (!this._menu) return []
    const menu = JSON.parse(this._menu) as Menu
    return menu.permissions
  }

  private isAllowed(permission: string): boolean {
    return this.user_permissions.includes(permission)
  }

  isAllowedAction(permissions: Array<string> | string): boolean {
    if (permissions instanceof Array)
      return permissions.every((permission) => this.isAllowed(permission))
    else return this.isAllowed(permissions)
  }

  get breadcrumbs(): Breadcrumb[] {
    const path = router.app.$route.path
    const breadcrumbs_json = breadcrumb[path]
    if (!breadcrumbs_json) return []
    else return breadcrumbs_json as Breadcrumb[]
  }

  @Mutation
  clearMenu() {
    this._menu = null
    localStorage.removeItem(StoreNames.USER_MENU)
  }

  @Mutation
  setMenu(payload: Menu) {
    this._menu = JSON.stringify(payload)
    localStorage.setItem(StoreNames.USER_MENU, this._menu)
  }

  @Mutation
  setIdParam(payload: string) {
    this._id_param = payload
    localStorage.setItem(StoreNames.ID_PARAM, this._id_param)
  }
  @Mutation
  setPerpageParam(payload: string) {
    this._perPage_param = payload
    localStorage.setItem(StoreNames.PERPAGE_PARAM, this._perPage_param)
  }

  @Action
  async initMenu(role: string) {
    const response = await http.get(`api/account/user_menu/${role}/`)

    this.setMenu(response.data)
    return Promise.resolve(response.data)
  }
}

const menuModule = new MenuStore({ store, name: 'menu' })
export default menuModule
