import { RouteConfig } from 'vue-router'

const residencyRoutes: Array<RouteConfig> = [
  {
    path: '/residency',
    name: 'Residency',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Residency/Residency/Residency.vue'),
  },
  {
    path: '/residency/residencyType',
    name: 'ResidencyType',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import('@/views/Residency/Settings/TypeResidency/TypeResidency.vue'),
  },
  {
    path: '/residency/typeDocumentResidency',
    name: 'TypeDocumentResidency',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        '@/views/Residency/Settings/TypeDocumentResidency/TypeDocumentResidency.vue'
      ),
  },
  {
    path: '/residency/typeResidencyStatus',
    name: 'TypeResidencyStatus',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        '@/views/Residency/Settings/TypeResidencyStatus/TypeResidencyStatus.vue'
      ),
  },
  {
    path: '/residency/residencyObservation',
    name: 'ResidencyObservation',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Residency/Observation/Observation.vue'),
    props: true,
  },
  {
    path: '/residency/residencyDocument',
    name: 'ResidencyDocument',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Residency/Document/Document.vue'),
  },
]
export default residencyRoutes
