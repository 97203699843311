import { RouteConfig } from 'vue-router'

const CCSSRoutes: Array<RouteConfig> = [
  {
    path: '/ccss',
    name: 'CCSS',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/CCSS/CCSS/CCSS.vue'),
  },
  {
    path: '/CCSS/typeRateCCSS',
    name: 'TypeRateCCSS',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import('@/views/CCSS/Settings/TypeRateCCSS/TypeRateCCSS.vue'),
  },
  {
    path: '/CCSS/typeStateCCSS',
    name: 'TypeStateCCSS',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import('@/views/CCSS/Settings/TypeStateCCSS/TypeStateCCSS.vue'),
  },
  {
    path: '/CCSS/dependentCCSS',
    name: 'DependentCCSS',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/CCSS/Dependent/Dependent.vue'),
  },
  {
    path: '/CCSS/documentCCSS',
    name: 'DocumentCCSS',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/CCSS/Document/Document.vue'),
  },
  {
    path: '/CCSS/accountNegative',
    name: 'acount_negative_ccss',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/CCSS/AccountNegative/AccountNegative.vue'),
  },
  {
    path: '/CCSS/accountNegativeIvm',
    name: 'acount_negative_ccss_ivm',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import('@/views/CCSS/AccountNegativeIvm/AccountNegativeIvm.vue'),
  },
]
export default CCSSRoutes
