import { RouteConfig } from 'vue-router'

const customerRoutes: Array<RouteConfig> = [
  {
    path: '/customerForm',
    name: 'customerForm',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/FormCustomer/FormCustomer/index.vue'),
  },
  {
    path: '/customerDependent',
    name: 'customerDependent',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/FormCustomer/DepedentForm/index.vue'),
  },
  {
    path: '/listCustomerForms',
    name: 'listCustomerForms',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/FormCustomer/AdminFormCustomer/index.vue'),
  },
]
export default customerRoutes
