import authModule from '@/store/auth'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoginRoutes from './loginroutes'
import DashboardRoutes from './dashboardroutes'
import AccountsRoutes from './accountsroutes'
import crmRoutes from './CrmRoutes'
import residencyRoutes from './residencyRoutes'
import insuranceRoutes from './insuranceRoutes'
import CCSSRoutes from './CCSSRoutes'
import legalRoutes from './LegalRoutes'
import emailRoutes from './EmailRoutes'
import UsersRoutes from './usersRoutes'
import CustomerRoutes from './customerRoutes'
import { validateRole } from '@/services/validate'

Vue.use(VueRouter)

const base_routes: Array<RouteConfig> = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404/404.vue'),
  },
]

const routes = base_routes.concat(
  LoginRoutes,
  DashboardRoutes,
  AccountsRoutes,
  crmRoutes,
  residencyRoutes,
  insuranceRoutes,
  CCSSRoutes,
  legalRoutes,
  emailRoutes,
  UsersRoutes,
  CustomerRoutes,
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

/* router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authModule.isAuthenticated) {
      next({
        path: '/login',
        query: {
          nextUrl: to.fullPath,
          message: i18n.t('toast.session_off').toString(),
        },
        replace: true,
      })
    } else next()
  } else next()
}) */

router.beforeEach((to, from, next) => {
  const authorize = to.matched.some((record) => record.meta.requiresAuth)
  //console.log(authorize, 'Auth')
  //se verifica que el usuario no sea un objeto vacio
  const userRol = authModule.Roles
  //se verifica si la ruta esta restringida por roles
  if (authorize) {
    //se deben optener los datos del usuario de lo contrario se redirige al login
    if (!userRol) {
      //console.log('No hay datos de usuario')
      return next({
        path: '/',
        query: {
          returnUrl: to.path,
        },
      })
    }
    //console.log(userRol[0], 'Rol de usuario')
    //const allowedRole = to.matched.some((record) => record.meta.allowedRoles)
    if (to.matched.some((record) => record.meta.allowedRoles !== undefined)) {
      if (!to.meta || validateRole(to.meta.allowedRoles)) next()
      else router.push({ name: '404' }).catch(console.log)
    } else next()
  } else next()
})

export default router
