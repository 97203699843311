<template>
  <div id="app">
    <single-layout v-if="currentTabComponent == 'SingleLayout'" />
    <empy-layout v-if="currentTabComponent == 'EmpyLayout'" />
    <box-layout v-if="currentTabComponent == 'BoxLayout'" />
  </div>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import menuModule from '@/store/menu'

@Component({
  components: {
    EmpyLayout: () => import('./layout/EmpyLayout.vue'),
    SingleLayout: () => import('./layout/SingleLayout.vue'),
    BoxLayout: () => import('./layout/BoxLayout.vue'),
  },
})
export default class App extends Vue {
  get currentTabComponent() {
    return menuModule.currentTabComponent
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
/* #app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
#app {
  font-family: 'Archivo Narrow', sans-serif;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html {
  overflow-y: auto;
}
</style>
