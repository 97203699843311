export const StoreNames = Object.freeze({
  USER_MENU: 'user_menu',
  AUTH_TOKEN: 'authToken',
  USER: 'user',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  PROFILE: 'profile',
  ID_PARAM: 'id_param',
  PERPAGE_PARAM: 'perPage_param',
})
