import { RouteConfig } from 'vue-router'

const LoginRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login/Login.vue'),
  },
]

export default LoginRoutes
